<template>
  <div class="storage">
    <transition name="bubble">
      <page-block class="storage-view__block">
        <main-card>
          <base-loader v-if="loader" />
          <div v-else>
            <div class="addons__label medium-title">
              {{ $t('params') }}
            </div>
            <div class="addons">
              <div class="addons__item">
                <div class="addons__label standart-text">
                  <label class="standart-title">
                    {{ $t('s3') }}
                  </label>
                </div>
                <div class="addons__value standart-text">s3.rusonyxcloud.ru</div>
              </div>
              <div class="addons__item">
                <div class="addons__label standart-text">
                  <label class="standart-title">
                    {{ $t('useSSL') }}
                  </label>
                </div>
                <div class="addons__value standart-text">Да</div>
              </div>
              <div class="addons__item">
                <div class="addons__label standart-text">
                  <label class="standart-title">
                    {{ $t('region') }}
                  </label>
                </div>
                <div class="addons__value standart-text">ru-msk</div>
              </div>
            </div>
            <div class="addons__label medium-title">
              {{ $t('cost') }}
            </div>
            <div class="cost">
              <!--              <div class="cost__item">-->
              <div class="cost__label standart-text">
                <label class="standart-title">
                  {{ $t('disk') }}
                </label>
              </div>
              <div class="cost__value standart-text">
                <!--                {{ objectSizeParser(getLimits?.stats?.size_kb_actual * 1024) }}-->
                {{ storageSize }}
              </div>
              <div class="cost__value standart-text">
                {{ $n(pricePerHour, 'currency', $i18n.locale) }}/ час
              </div>
              <div class="cost__value standart-text">
                {{ $n(pricePerHour * 24, 'currency', $i18n.locale) }}/ сутки
              </div>
              <div class="cost__value standart-text">
                {{ $n(pricePerHour * 24 * 30, 'currency', $i18n.locale) }}/ месяц
              </div>
              <!--              </div>-->
            </div>
            <div class="addons__label medium-title">
              {{ $t('limits') }}
            </div>
            <div class="limits">
              <!--              <div class="cost__item">-->
              <div class="limits__label standart-text">
                <label class="standart-title">
                  {{ $t('disk') }}
                </label>
              </div>
              <div class="limits__value standart-text">
                <!--                {{ objectSizeParser(getLimits?.stats?.size_kb_actual * 1024) }}-->
                {{ storageLimit }}
              </div>
              <!--              </div>-->
            </div>
          </div>
        </main-card>
      </page-block>
    </transition>
  </div>
</template>

<script>
import MainCard from '@/components/MainCard/MainCard';
import RadioButton from 'primevue/radiobutton';
import { OPStorage } from '@/models/OP/OPStorage';
import Button from 'primevue/button';
import modals from '@/mixins/modals';
import showErrorOpenStackModal from '@/mixins/showErrorOpenStackModal';
import CheckboxBlock from '@/components/Configurator/components/CheckboxBlock';
import { IS_PROD } from '@/layouts/LoginPage/mixins/currentProvider';
import { dev } from '@/layouts/LoginPage/mixins/currentProvider';
import { objectSizeParser } from '@/utils/parsers/objectSizeParser';
export default {
  name: 'S3View',
  components: {
    MainCard,
  },
  mixins: [modals, showErrorOpenStackModal],
  props: {
    tariff: {
      type: OPStorage,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      mode: 'base',
      loader: false,
      selectedCategory: null,
      currentSize: '',
      currentCount: '',
      categories: [
        { name: 'private', key: 'private' },
        { name: 'public', key: 'public-read' },
      ],
      formData: {},
    };
  },
  computed: {
    id() {
      return this.$store.state.moduleStack.current;
    },
    storages() {
      return this.$store.state.moduleStack.storages;
    },
    getLimits() {
      return this.$store.state.moduleStack.userInfo;
    },
    storageSize() {
      // const size = this.storages.reduce((sum, i) => sum + i.size, 0);
      if (this.getLimits?.stats?.size_kb_actual) {
        const size = this.getLimits?.stats?.size_kb_actual * 1024;
        return objectSizeParser(size);
      } else return objectSizeParser(0);
    },
    storageLimit() {
      // const size = this.storages.reduce((sum, i) => sum + i.size, 0);
      if (this.getLimits?.user_quota_kb) {
        const size = this.getLimits?.user_quota_kb * 1024;
        return objectSizeParser(size);
      } else return null;
    },
    pricePerHour() {
      if (this.getLimits?.stats?.size_kb_actual && !!this.getPrice) {
        return (this.getLimits?.stats?.size_kb_actual * this.getPrice) / 1024 ** 2;
        // return objectSizeParser(size);
      } else return 0;
    },
    getPrice() {
      return this.$store.state.moduleStack.price.length
        ? this.$store.state.moduleStack.price.find(i => i.intname === 'swift_STANDARD_size')[dev]
        : null;
    },
    s3key() {
      return this.$store.state.moduleStack.s3Keys[0];
    },
  },
  watch: {
    tariff: {
      handler: function (event) {},
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.$store.dispatch('moduleStack/getS3StoragesKeys', this.id);
    this.getStackPrice();
    this.getS3BucketList();
    this.getS3Limit();
  },
  methods: {
    async getStackPrice() {
      return this.$store.dispatch('moduleStack/getPrice', this.tariff.id).then(() => {});
    },
    getS3BucketList() {
      return this.$store.dispatch('moduleStack/getS3BucketList', {
        id: this.id,
      });
    },
    getS3Limit() {
      return this.$store.dispatch('moduleStack/getS3Limit', {
        id: this.id,
      });
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "all": "Применить к содержимому контейнера",
      "name": "Имя",
      "limits": "Лимиты",
      "cost": "Стоимость",
      "disk": "Диск",
      "s3": "URL-адрес конечной точки объектного хранилища (S3 endpoint URL):",
      "useSSL": "Использование SSL для защищенного соединения:",
      "region": "Регион облачного хранилища:",
      "params": "Параметры объектного хранилища",
      "descr": "Применить ко всем вложенным объектам контейнера.",
      "cache": "Кэширование",
      "container": "Тип контейнера",
      "type": "Настройки конфиденциальности",
      "x-container-meta-quota-bytes": "Суммарный размер, ГБ",
      "x-container-meta-quota-count": "Количество, шт",
      "text": "Для сброса лимита - уберите символы из соответствующих полей. Настройки лимитов применяются только при загрузке новых файлов и не влияют на уже хранящиеся файлы.",
      "sum": "Суммарный размер, ГБ",
      "currentSize": "Текущий объем контейнера",
      "currentCount": "Текущее количество объектов",
      "private": "Приватный",
      "public": "Публичный",
      "count": "Количество, шт",
      "limit": "Лимиты объектов контейнера",
      "port": "Входящий порт",
      "vppr": "Служебные порты",
      "cpu": "vCPU",
      "ram": "Память",
      "networkDisk": "Сетевой диск"
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.storage {
  max-width: 50rem
  &-view{
    &__block{
      margin-top: 1rem;
    }
  }
}
.tariff {
  &-plan,
  &-autoprolong {

    &__btns {
      margin: -0.5em -0.75rem;
    }

    &__btn {
      min-width: 130px;
      margin: 0.5em 0.75rem;
    }
  }
}
.field-radiobutton {
  margin-right: 1rem
}
.cost {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 1.5rem;


}
.limits {
  display: flex;
  gap: 5rem;
  //justify-content: space-between;


}
.addons {
  &__input{
    height: 36px;
    width: 100%
  }
  &__value{
    min-width: 15rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

  &-radio {
      margin-right: 0.5rem
    }
  }

  &__item {
    +breakpoint(sm-and-up) {
      flexy(flex-start, center);
      height: 45px;
    }

    &-btn {
      width: 130px;
      margin-top: 1rem

      & + & {
        margin-left: 2rem
      }
    }

    & + & {

      +breakpoint(sm-and-up) {
        margin-top: -0.8rem;
        transition: border-color $anim-base-duration;
      }
    }
  }

  &__label {
    +breakpoint(sm-and-up) {
      flex: 0 0 32rem;
      margin-right: 0.5rem;
      //margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
}
</style>
