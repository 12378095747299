<template>
  <div class="storage">
    <transition name="bubble">
      <page-block class="storage-view__block">
        <main-card>
          <base-loader v-if="loader" />
          <div v-else>
            <div class="addons__label medium-title">
              {{ $t('params') }}
            </div>
            <div class="addons">
              <div class="addons__item">
                <div class="addons__label standart-text">
                  <label class="standart-title">
                    {{ $t('s3') }}
                  </label>
                </div>
                <div class="addons__value standart-text">s3.rusonyxcloud.ru</div>
              </div>
              <div class="addons__item">
                <div class="addons__label standart-text">
                  <label class="standart-title">
                    {{ $t('useSSL') }}
                  </label>
                </div>
                <div class="addons__value standart-text">Да</div>
              </div>
              <div class="addons__item">
                <div class="addons__label standart-text">
                  <label class="standart-title">
                    {{ $t('region') }}
                  </label>
                </div>
                <div class="addons__value standart-text">ru-msk</div>
              </div>
            </div>
            <div class="addons__label medium-title">
              {{ $t('type') }}
            </div>
            <br />
            <div class="addons">
              <div class="addons__item">
                <div class="addons__label standart-text">{{ $t('container') }}</div>
                <div v-if="mode === 'base'" class="addons__value standart-text">
                  {{ tariff.policy.IsPublic === true ? 'Публичный' : 'Приватный' }}
                </div>
                <div v-else class="addons__value standart-text">
                  <div class=""></div>
                  <div v-for="category of categories" :key="category.key" class="field-radiobutton">
                    <RadioButton
                      :id="category.key"
                      v-model="selectedCategory"
                      name="category"
                      :value="category"
                      class="addons__value-radio"
                    />
                    <label :for="category.key">{{ $t(category.name) }}</label>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="addons">
              <div v-if="mode === 'base'" class="addons__item">
                <div class="addons__item-btn">
                  <Button
                    type="button"
                    label="Изменить"
                    icon="pi pi-pencil"
                    class="p-button-primary"
                    style="width: 130px"
                    @click="edit"
                  ></Button>
                </div>
              </div>
              <div v-else class="addons__item">
                <div class="addons__item-btn">
                  <Button
                    type="button"
                    label="Сохранить"
                    :disabled="!isChanged"
                    icon="pi pi-check"
                    class="p-button-primary"
                    style="width: 130px"
                    @click="send"
                  ></Button>
                </div>
                <div class="addons__item-btn">
                  <Button
                    type="button"
                    label="Отмена"
                    icon="pi pi-times"
                    style="width: 130px"
                    class="p-button-danger"
                    @click="cancel"
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        </main-card>
      </page-block>
    </transition>
  </div>
</template>

<script>
import MainCard from '@/components/MainCard/MainCard';
import RadioButton from 'primevue/radiobutton';
import { OPStorage } from '@/models/OP/OPStorage';
import Button from 'primevue/button';
import modals from '@/mixins/modals';
import showErrorOpenStackModal from '@/mixins/showErrorOpenStackModal';
import CheckboxBlock from '@/components/Configurator/components/CheckboxBlock';
export default {
  name: 'ObjectsView',
  components: {
    MainCard,
    // CheckboxBlock,
    RadioButton,
    Button,
  },
  mixins: [modals, showErrorOpenStackModal],
  props: {
    tariff: {
      type: OPStorage,
      required: true,
      validator: obj => obj instanceof OPStorage,
    },
  },
  data() {
    return {
      mode: 'base',
      loader: false,
      selectedCategory: null,
      currentSize: '',
      currentCount: '',
      categories: [
        { name: 'private', key: 'private' },
        { name: 'public', key: 'public-read' },
      ],
      formData: {},
    };
  },
  computed: {
    isPublic() {
      return this.tariff.policy.IsPublic === true;
    },
    id() {
      return this.$store.state.moduleStack.current;
    },
    currentPolicy() {
      return {
        Sid: 'public',
        Action: 's3:GetObject',
        Effect: 'Allow',
        Principal: '*',
        Resource: `arn:aws:s3:::${this.tariff.name}/*`,
      };
    },
    defaultPolicy() {
      return {
        Version: '2012-10-17',
        Statement: [this.currentPolicy],
      };
    },
    currentType() {
      return this.selectedCategory.name === 'public';
    },
    isChanged() {
      return this.currentType !== this.isPublic;
    },
    s3key() {
      return this.$store.state.moduleStack.s3Keys[0];
    },
  },
  watch: {
    tariff: {
      handler: function (event) {
        if (event) {
          this.selectedCategory =
            event.policy.IsPublic === true
              ? { key: 'public-read', name: 'public' }
              : { key: 'private', name: 'private' };
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.updateFormData(this.tariff);
    this.updateStorageInfo('/');
  },
  methods: {
    async updateStorageInfo(prefix) {
      this.loader = true;
      const params = {
        storage: this.tariff.name,
        objectForAcl: true,
      };
      if (prefix) params.prefix = prefix;
      await this.$store
        .dispatch('moduleStack/fetchCurrentStorages', params)
        .finally(() => (this.loader = false));
    },
    updateFormData(event) {
      if (event) {
        this.selectedCategory =
          event.policy.IsPublic === true
            ? { key: 'public-read', name: 'public' }
            : { key: 'private', name: 'private' };
      }
    },
    edit() {
      this.mode = 'edit';
    },
    cancel() {
      this.mode = 'base';
      this.selectedCategory =
        this.tariff.policy.IsPublic === true
          ? { key: 'public-read', name: 'public' }
          : { key: 'private', name: 'private' };
    },
    onChange(name, event) {
      this.formData[name] = event;
    },
    getS3BucketList() {
      return this.$store.dispatch('moduleStack/getS3BucketList', {
        id: this.id,
      });
    },
    send: async function () {
      this.mode = 'base';
      // this.config.value = 'off';
      let params;
      if (this.isPublic) {
        // params = {
        //   name: this.tariff.name,
        //   policy: null,
        // };
        await this.$store.dispatch('moduleStack/deleteBucketPolicy', this.tariff.name);
      } else {
        params = {
          name: this.tariff.name,
          policy: this.defaultPolicy,
        };
        console.log(params);
        await this.$store.dispatch('moduleStack/putBucketPolicy', params).then(async data => {
          console.log(data);
          // await this.$store.dispatch('moduleStack/fetchCurrentStorages', {
          // storage: this.tariff.name,
          // });
        });
      }
      await this.$store.dispatch('moduleStack/fetchProjectStorages', {
        delimiter: '/',
        id: this.id,
      });
      await this.getS3BucketList();
      // .catch(e => this.showErrorS3(e));
      // if (!this.addAclToAllObjectStorage)
      //   await this.$store
      //     .dispatch('moduleStack/putBucketPolicy', params)
      //     .then(async () => {
      //       await this.$store.dispatch('moduleStack/fetchProjectStorages');
      //     })
      //     .catch(e => this.showErrorS3(e));
      // else {
      //   await this.$store
      //     .dispatch('moduleStack/putBucketPolicy', params)
      //     .then(async data => {
      //       // console.log(data);
      //       // this.tariff.objects.forEach(x => {
      //       this.tariff.objectForAcl.forEach(x => {
      //         console.log(x);
      //         const payload = {
      //           name: this.tariff.name,
      //           obj: x.key,
      //           policy: this.selectedCategory.key,
      //         };
      //         this.$store
      //           .dispatch('moduleStack/setACLObject', payload)
      //           .catch(e => console.error(e));
      //       });
      //       await this.$store.dispatch('moduleStack/fetchProjectStorages');
      //     })
      //     .catch(e => this.showErrorS3(e));
      // }
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "all": "Применить к содержимому контейнера",
      "name": "Имя",
      "s3": "URL-адрес конечной точки объектного хранилища (S3 endpoint URL):",
      "useSSL": "Использование SSL для защищенного соединения:",
      "region": "Регион вашего облачного хранилища:",
      "params": "Параметры объектного хранилища",
      "descr": "Применить ко всем вложенным объектам контейнера.",
      "cache": "Кэширование",
      "container": "Тип контейнера",
      "type": "Настройки конфиденциальности",
      "x-container-meta-quota-bytes": "Суммарный размер, ГБ",
      "x-container-meta-quota-count": "Количество, шт",
      "text": "Для сброса лимита - уберите символы из соответствующих полей. Настройки лимитов применяются только при загрузке новых файлов и не влияют на уже хранящиеся файлы.",
      "sum": "Суммарный размер, ГБ",
      "currentSize": "Текущий объем контейнера",
      "currentCount": "Текущее количество объектов",
      "private": "Приватный",
      "public": "Публичный",
      "count": "Количество, шт",
      "limit": "Лимиты объектов контейнера",
      "port": "Входящий порт",
      "vppr": "Служебные порты",
      "cpu": "vCPU",
      "ram": "Память",
      "disk": "Локальный диск",
      "networkDisk": "Сетевой диск"
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.storage {
  max-width: 50rem
}
.tariff {
  &-plan,
  &-autoprolong {

    &__btns {
      margin: -0.5em -0.75rem;
    }

    &__btn {
      min-width: 130px;
      margin: 0.5em 0.75rem;
    }
  }
}
.field-radiobutton {
  margin-right: 1rem
}
.addons {
  &__input{
    height: 36px;
    width: 100%
  }
  &__value{
    min-width: 15rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

  &-radio {
      margin-right: 0.5rem
    }
  }

  &__item {
    +breakpoint(sm-and-up) {
      flexy(flex-start, center);
      height: 45px;
    }

    &-btn {
      width: 130px;
      margin-top: 1rem

      & + & {
        margin-left: 2rem
      }
    }

    & + & {

      +breakpoint(sm-and-up) {
        margin-top: -0.8rem;
        transition: border-color $anim-base-duration;
      }
    }
  }

  &__label {
    +breakpoint(sm-and-up) {
      flex: 0 0 32rem;
      margin-right: 0.5rem;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
}
</style>
